<template>
  <div class="cardDeta">

    <ul class="deta">
      <h2>实体卡信息</h2>
      <li>
        卡号： {{ data.cardNo }}
      </li>
      <li>
        卡片状态： {{ data.cardStatus }}
      </li>
      <li>
        包含产品名称： {{ data.productName }}
      </li>
      <li>
        包含产品类别： {{ data.productCategory }}
      </li>
      <li>
        价格： {{ data.productPrice }}
      </li>
      <li>
        生成日期： {{ data.createTime }}
      </li>
      <li>
        生成人员： {{ data.createBy }}
      </li>
    </ul>

    <ul class="deta" v-if="data.saleDate" >
      <h2>销售信息</h2>
      <li>
        卖出日期：  {{ data.saleDate }}
      </li>
      <li>
        销售人员：  {{ data.slaePerson }}
      </li>
      <li v-if="data.buyPerson" >
        购买人：  {{ data.buyPerson }}
      </li>
      <li v-if="data.buyPhone" >
        购买人手机号： {{ data.buyPhone }}
      </li>
      <li>
        卖出实际价格：  {{ data.amount }}
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "CardDeta",
  data() {
    return {
      data: {}
    }
  },
  created() {

    this.get_deta(this.$route.query.id);

  },
  methods: {
    get_deta(y) {
      this.$get("card",y,true)
        .then(res => {
          if(res) {
            console.log(res)

            this.data= res.data;

          }
        })
    }

  }
}
</script>

<style lang="scss" scoped>
  .cardDeta{
    padding: 20px;
    background: white;
    border-radius: 6px;
    min-height: calc(100vh - 100px);
    .deta{
      margin-bottom: 36px;
      h2{
        padding: 15px 0;
        border-bottom: 1px solid rgba(187, 187, 187, 100);
        margin-bottom: 25px;
      }
      li{
        margin-bottom: 17px;
      }
    }

    .deta li:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .cardDeta .deta:nth-last-child(1) {
    margin-bottom: 0;
  }

</style>
